<template>
  <div class="sequence-step">
    <LmsSwitch size="large" :withIcon="true" :value.sync="computedValue" :updateKey="updateKey" />
    <div>
      <span>{{ $t('label.sequencedPass') }}</span>
      <span>{{ label }}</span>
    </div>
  </div>
</template>

<script>
import LmsSwitch from '@/components/LmsSwitch.vue';

export default {
  name: 'SequencedStepSwitcher',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    updateKey: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LmsSwitch,
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(bool) {
        this.$emit('update', bool);
      },
    },
  },
};
</script>
