<template>
  <div class="content-block__empty">
    <div class="content-block__empty-title">{{ $t('header.addMaterialToTheCourse') }}</div>
    <div class="content-block__empty-image">
      <div class="image f-center">
        <img src="@/assets/images/svg/empty-material.svg" alt="Ручка" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseContentEmpty',
};
</script>
