<template>
  <button class="small-button" @click="$emit('click')">
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- eslint-disable max-len -->
      <path
        d="M7.7093 2.58142H2.49095C2.09553 2.58142 1.7163 2.7385 1.43669 3.01811C1.15708 3.29772 1 3.67695 1 4.07238V14.5091C1 14.9045 1.15708 15.2837 1.43669 15.5633C1.7163 15.8429 2.09553 16 2.49095 16H12.9276C13.3231 16 13.7023 15.8429 13.9819 15.5633C14.2615 15.2837 14.4186 14.9045 14.4186 14.5091V9.29072"
        stroke="#F2F2F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <!-- eslint-disable max-len -->
      <path
        d="M13.3004 1.46318C13.5969 1.16661 13.9992 1 14.4186 1C14.838 1 15.2402 1.16661 15.5368 1.46318C15.8334 1.75975 16 2.16198 16 2.5814C16 3.00081 15.8334 3.40304 15.5368 3.69961L8.45475 10.7816L5.47284 11.5271L6.21832 8.54522L13.3004 1.46318Z"
        stroke="#F2F2F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'SmallEditButton',
};
</script>
