<template>
  <div>
    <span class="breadcrumb__course" @click="resetContentLevel">{{
      $t('buttonLabels.courseContent')
    }}</span>
    <span class="breadcrumb__divider">&nbsp;/&nbsp;&nbsp;</span>
    <span
      class="breadcrumb__module"
      :class="{
        'breadcrumb__module--active': contentLevel === 2,
      }"
      @click="setGroupIdOfLevelTwo(null)"
    >
      {{ breadCrumbNameLevelOne }}
    </span>
    <span v-if="contentLevel === 2" class="breadcrumb__divider">&nbsp;/&nbsp;&nbsp;</span>
    <span v-if="contentLevel === 2" class="breadcrumb__module">
      {{ breadCrumbNameLevelTwo }}
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CourseContentBreadCrumbs',
  computed: {
    ...mapGetters('courseContentTeacher', [
      'contentLevel',
      'selectedGroupOfLevelOne',
      'selectedGroupOfLevelTwo',
    ]),
    breadCrumbNameLevelOne() {
      return this.selectedGroupOfLevelOne?.name || this.$t('label.newGroup');
    },
    breadCrumbNameLevelTwo() {
      return this.selectedGroupOfLevelTwo?.name || this.$t('label.newGroup');
    },
  },
  methods: {
    ...mapActions('courseContentTeacher', ['setGroupIdOfLevelOne', 'setGroupIdOfLevelTwo']),
    resetContentLevel() {
      this.setGroupIdOfLevelOne(null);
      this.setGroupIdOfLevelTwo(null);
    },
  },
};
</script>
