<template>
  <svg
    width="129"
    height="129"
    viewBox="0 0 129 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M125.586 84.7239V111.965C125.586 115.578 124.151 119.042
                        121.597 121.597C119.042 124.151 115.578 125.586 111.965
                        125.586H16.6207C13.0082 125.586 9.54377 124.151 6.9894
                        121.597C4.43503 119.042 3 115.578 3 111.965V84.7239"
      stroke="#666666"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.1033 37.0516L64.0516 3L30 37.0516"
      stroke="#666666"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64 3V84.724"
      stroke="#666666"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadFile',
};
</script>
