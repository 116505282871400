var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-material"},[_c('b-modal',{attrs:{"id":"modal-video","centered":"","hide-footer":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"modal-material__title"},[_vm._v(" "+_vm._s(_vm.isModalTypeCreated ? _vm.$t('header.createVideo') : _vm.$t('header.editVideo'))+" ")])]},proxy:true},{key:"modal-header-close",fn:function(){return [_c('SmallCloseButton')]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"selected-material",class:{ 'selected-material--creation': _vm.isModalTypeCreated }},[_c('div',{staticClass:"selected-material__info info"},[_c('div',{staticClass:"info__title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('div',{staticClass:"info__subtitle"},[_vm._v(" "+_vm._s(_vm.isModalTypeCreated ? _vm.$t('supportText.youtubeAndVimeoSupported') : _vm.$t('supportText.canChangeTitleDescriptionLink'))+" ")])]),_c('div',{staticClass:"selected-material__action meta"},[_c('div',{staticClass:"meta__video-link"},[_c('LmsInput',{class:{
                invalid: _vm.$v.videoLink.$dirty && _vm.$v.videoLink.$anyError,
                correct: _vm.$v.videoLink.$dirty && !_vm.$v.videoLink.$anyError,
              },attrs:{"id":"material-video-link","label":_vm.$t('placeholders.enterVideoLink'),"value":_vm.link},on:{"update:value":function($event){_vm.link=$event},"change":_vm.validateLink}})],1),(_vm.$v.videoLink.$dirty && !_vm.$v.videoLink.required)?_c('div',{staticClass:"helper-text"},[_vm._v(" "+_vm._s(_vm.$t('errorMessages.pleaseFill'))+" ")]):(_vm.$v.videoLink.$dirty && !_vm.$v.videoLink.existingLink)?_c('div',{staticClass:"helper-text"},[_vm._v(" "+_vm._s(_vm.$t('errorMessages.provideValidLink'))+" ")]):_vm._e(),_c('div',{staticClass:"meta__title"},[_c('LmsInput',{class:{
                invalid: _vm.$v.title.$dirty && !_vm.$v.title.required,
                correct: _vm.$v.title.$dirty && !_vm.$v.title.$anyError,
              },attrs:{"id":"material-title","label":_vm.$t('placeholders.enterTitleForMaterial'),"value":_vm.title},on:{"update:value":function($event){_vm.title=$event},"change":() => _vm.$v.title.$touch()}})],1),(_vm.$v.title.$dirty && !_vm.$v.title.required)?_c('div',{staticClass:"helper-text"},[_vm._v(" "+_vm._s(_vm.$t('errorMessages.pleaseFill'))+" ")]):_vm._e(),_c('div',{staticClass:"meta__description"},[_c('LmsInput',{class:{
                correct: _vm.$v.description.$dirty && !_vm.$v.description.$anyError,
              },attrs:{"id":"material-description","label":_vm.$t('placeholders.enterDescriptionForMaterial'),"value":_vm.description},on:{"update:value":function($event){_vm.description=$event},"change":() => _vm.$v.description.$touch()}})],1),_c('div',{staticClass:"meta__score"},[_c('div',{staticClass:"score-container"},[_c('span',{staticClass:"score-title"},[_vm._v(_vm._s(_vm.$t('label.scorePerView')))]),_c('LmsInput',{class:{
                  'error-message--activate':
                    _vm.$v.max_score.$dirty && !_vm.$v.max_score.maximumNotExceeded,
                },attrs:{"id":"material-score","type":"number","placeholder":"max 999","value":_vm.max_score},on:{"update:value":function($event){_vm.max_score=$event}}}),_c('div',{staticClass:"error-message",class:{
                  'error-message--activate':
                    _vm.$v.max_score.$dirty && !_vm.$v.max_score.maximumNotExceeded,
                }},[_c('p',[_vm._v(_vm._s(_vm.$t('label.maximum'))+" 999")])])],1)]),_c('div',{staticClass:"meta__action"},[_c('AddButton',{attrs:{"title":_vm.isModalTypeCreated ? _vm.$t('buttonLabels.addVideo') : _vm.$t('buttonLabels.saveChanges'),"isDisabled":_vm.loading},on:{"click":_vm.saveVideoMaterial}})],1)])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }