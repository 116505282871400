<template>
  <div>
    <div class="groups-block" :class="{ 'groups-block--visible': groupsOfCourse.length }">
      <CourseGroupCard
        v-for="group in groupsOfCourse"
        :key="group._id"
        :group="group"
        :openGroup="openGroup"
      />
    </div>

    <ContentList
      :key="contentLevel"
      handle=".material-tile__drag"
      :list="materialsOfCourse"
      type="material"
      :disableDragAndDrop="isDisabledDragAndDrop"
      @changeOrder="changeOrder"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ContentList from '@/components/ContentList.vue';
import CourseGroupCard from '@/components/Teacher/CourseContent/CourseGroupCard.vue';

export default {
  name: 'CourseContent',
  components: {
    CourseGroupCard,
    ContentList,
  },
  data() {
    return {
      isDisabledDragAndDrop: false,
    };
  },
  computed: {
    ...mapGetters('courseContentTeacher', [
      'contentLevel',
      'groupIdOfLevelOne',
      'groupIdOfLevelTwo',
      'groupsOfCourse',
      'materialsOfCourse',
      'activitiesOfCurrentLevelContent',
    ]),
  },
  methods: {
    ...mapActions('courseContentTeacher', [
      'reorderMaterials',
      'setGroupIdOfLevelOne',
      'setGroupIdOfLevelTwo',
    ]),
    // eslint-disable-next-line no-unused-vars
    async changeOrder(materials) {
      const oldMaterialOrder = [...this.activitiesOfCurrentLevelContent];

      if (this.groupsOfCourse.length) {
        materials.unshift(...this.groupsOfCourse);
      }

      const oldIds = oldMaterialOrder.reduce((previousValue, currentValue) => {
        previousValue.push(currentValue._id);
        return previousValue;
      }, []);
      const newIds = materials.reduce((previousValue, currentValue) => {
        previousValue.push(currentValue._id);
        return previousValue;
      }, []);

      // eslint-disable-next-line no-nested-ternary
      const id = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;
      const payload = {
        programId: this.$route.params.programId,
        id,
        oldIds,
        newIds,
      };
      this.isDisabledDragAndDrop = true;
      this.reorderMaterials({
        materials,
        oldMaterialOrder,
        payload,
      })
        .catch(() => {})
        .finally(() => (this.isDisabledDragAndDrop = false));
    },
    openGroup(activityId) {
      if (this.contentLevel === 0) {
        return this.setGroupIdOfLevelOne(activityId);
      }
      if (this.contentLevel === 1) {
        return this.setGroupIdOfLevelTwo(activityId);
      }
    },
  },
};
</script>
