<template>
  <button class="small-button" @click.stop="$emit('remove')">
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.91638H2.33333H13"
        stroke="white"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33447 3.91667V2.58333C4.33447 2.22971 4.47495 1.89057 4.725
        1.64052C4.97505 1.39048 5.31418 1.25 5.66781 1.25H8.33447C8.68809
        1.25 9.02723 1.39048 9.27728 1.64052C9.52733 1.89057 9.66781 2.22971
        9.66781 2.58333V3.91667M11.6678 3.91667V13.25C11.6678 13.6036 11.5273
        13.9428 11.2773 14.1928C11.0272 14.4429 10.6881 14.5833 10.3345
        14.5833H3.66781C3.31418 14.5833 2.97505 14.4429 2.725 14.1928C2.47495
        13.9428 2.33447 13.6036 2.33447 13.25V3.91667H11.6678Z"
        stroke="white"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66553 7.25V11.25"
        stroke="white"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33447 7.25V11.25"
        stroke="white"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'SmallDeleteButton',
};
</script>
