<template>
  <svg width="82" height="80" viewBox="0 0 82 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.604 52.6802V69.5736C79.604 71.8138 78.6955 73.9622
                  77.0785 75.5463C75.4614 77.1303 73.2682 78.0203 70.9813
                  78.0203H10.6227C8.33579 78.0203 6.14258 77.1303 4.52552
                  75.5463C2.90846 73.9622 2 71.8138 2 69.5736V52.6802"
      stroke="#858E9A"
      stroke-width="3.72083"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.2056 23.1167L40.649 2L19.0923 23.1167"
      stroke="#858E9A"
      stroke-width="3.72083"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.6162 2V52.6802"
      stroke="#858E9A"
      stroke-width="3.72083"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadFileSmall',
};
</script>
